import adaptiveRknBase from "./adaptiveRknBase";

export default class extends adaptiveRknBase {
  constructor(params) {
    super(params);
    this.errorOrder = 12;
    this.coefficients = [
      [0.0002],
      [0.00026666666666666668, 0.00053333333333333336],
      [0.0029166666666666668, -0.0041666666666666666, 0.00625],
      [0.0016460905349794238, 0, 0.0054869684499314125, 0.0017558299039780521],
      [
        0.0019456,
        0,
        0.0071517460317460316,
        0.0029127111111111113,
        0.00078994285714285709
      ],
      [
        0.00056640625,
        0,
        0.00088097304894179892,
        -0.00043692129629629631,
        0.00033900669642857143,
        -9.9464699074074069e-5
      ],
      [
        0.0030833333333333333,
        0,
        0,
        0.0017777777777777779,
        0.0027,
        0.0015782828282828283,
        0.01086060606060606
      ],
      [
        0.00365183937480113,
        0,
        0.0039651717140723429,
        0.0031972582629306284,
        0.0082214673068554348,
        -0.0013130926959572379,
        0.0097715869680648684,
        0.0037557690692328338
      ],
      [
        0.003707241068718501,
        0,
        0.0050820458545552862,
        0.001174708002175412,
        -0.021147629915126993,
        0.060104636981078811,
        0.020105734768506189,
        -0.028350750122933581,
        0.014879568918581932
      ],
      [
        0.035125376560733439,
        0,
        -0.008615749195138479,
        -0.0057914480510079169,
        1.9455548237826159,
        -3.4351238674565137,
        -0.10930701107475221,
        2.3496383118995166,
        -0.75600940868702293,
        0.10952897222156927
      ],
      [
        0.020527792537482496,
        0,
        -0.00728644676448018,
        -0.0021153556079618403,
        0.92758079687235218,
        -1.6522824844257367,
        -0.021079563005686568,
        1.2065364326207872,
        -0.41371447700106612,
        0.090798739828096539,
        0.0053555526005339849
      ],
      [
        -0.14324078875545515,
        0,
        0.012528703773091817,
        0.0068260191639698273,
        -4.7995553955743873,
        5.6986250439519415,
        0.75534303695236449,
        -0.12755487858281084,
        -1.9605926051117384,
        0.91856090566352622,
        -0.23880085505284432,
        0.15911081357234216
      ],
      [
        0.80450192055204894,
        0,
        -0.016658527067011247,
        -0.021415834042629735,
        16.827235928962466,
        -11.172835357176098,
        -3.3771592972263238,
        -15.243326655360846,
        17.179835738215417,
        -5.4377192398239949,
        1.3878671618364655,
        -0.59258277326528119,
        0.029603873171297354
      ],
      [
        -0.913296766697358,
        0,
        0.0024112725757805178,
        0.017658122693861741,
        -14.851649779720384,
        2.1589708670045757,
        3.99791558311788,
        28.434151800232232,
        -25.2593643549416,
        7.7338785423622376,
        -1.8913028948478674,
        1.0014845070224718,
        0.0046411995991090518,
        0.011218755022148957
      ],
      [
        -0.27519629720559396,
        0,
        0.036611888779154923,
        0.009789519688231562,
        -12.293062345886211,
        14.207226453937903,
        1.5866476906789537,
        2.4577735327595946,
        -8.9351936944032726,
        4.3736727316134072,
        -1.8347181765449492,
        1.1592085289061491,
        -0.017290253165383924,
        0.019325977904460768,
        0.0052044429375549929
      ],
      [
        1.3076391847404059,
        0,
        0.017364109189745843,
        -0.018544456454265796,
        14.811522032867726,
        9.38317630848247,
        -5.2284261999445425,
        -48.95128052584765,
        38.297096034337926,
        -10.58738133697598,
        2.4332304376226275,
        -1.0453406042575444,
        0.0717732095086726,
        0.0021622109708082783,
        0.0070095957596025141,
        0
      ]
    ];
    this.delta = [
      0.02,
      0.04,
      0.1,
      0.13333333333333333,
      0.16,
      0.05,
      0.2,
      0.25,
      0.33333333333333331,
      0.5,
      0.55555555555555558,
      0.75,
      0.8571428571428571,
      0.94521622227201429,
      1,
      1
    ];
    this.alpha = [
      0.012127868517185414,
      0,
      0,
      0,
      0,
      0,
      0.086297462515688747,
      0.25254695811871469,
      -0.19741867993268231,
      0.2031869190789726,
      -0.020775808077714918,
      0.10967804874502014,
      0.038065132526466504,
      0.01163406880432423,
      0.0046580297040248785,
      0,
      0
    ];
    this.beta = [
      0.012127868517185414,
      0,
      0,
      0,
      0,
      0,
      0.090839434227040786,
      0.31568369764839338,
      -0.26322490657690972,
      0.30478037861845891,
      -0.041551616155429835,
      0.2467756096762953,
      0.15226053010586602,
      0.081438481630269607,
      0.085025711938908108,
      -0.0091551896300779631,
      0.025
    ];
    this.alphaHat = [
      0.017008701907006991,
      0,
      0,
      0,
      0,
      0,
      0.0722593359308314,
      0.372026177326753,
      -0.40182114500930355,
      0.33545506830135169,
      -0.13130650107533182,
      0.18943190661604864,
      0.026840802040029046,
      0.016305665605917924,
      0.0037999883566965944,
      0,
      0
    ];
  }
}
