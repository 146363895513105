export default [
  {
    name: "Sun",
    m: 1,
    radius: 10270,
    massType: "star",
    bump: false,
    luminosity: 1,
    temperature: 5778,
    tilt: 0,
    spacecraft: false,
    orbitalPeriod: 0,
    bodyType: "Star"
  },
  {
    name: "Teegarden's Star",
    m: 0.09,
    radius: 1074.418604651163,
    temperature: 2904,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "Kepler-62",
    m: 0.69,
    radius: 6251.162790697675,
    temperature: 4925,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "GJ 667 C",
    m: 0.33,
    radius: 3125.5813953488373,
    temperature: 3350,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "Epsilon Eridani",
    m: 0.78,
    radius: 7423.2558139534885,
    temperature: 4400,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "16 Cygni B",
    m: 1.08,
    radius: 11037.20930232558,
    temperature: 5750,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "Gliese 581",
    m: 0.31,
    radius: 3223.2558139534885,
    temperature: 3396,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "18 Delphini",
    m: 2.3,
    radius: 83023.2558139535,
    temperature: 4979,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "42 Draconis",
    m: 0.98,
    radius: 215176.74418604653,
    temperature: 4200,
    massType: "star",
    bodyType: "Star"
  },
  {
    name: "Luyten's Star",
    m: 0.26,
    radius: 3594,
    massType: "star",
    bump: false,
    luminosity: 0.0088,
    temperature: 3150,
    tilt: 0,
    spacecraft: false,
    orbitalPeriod: 0,
    bodyType: "Star"
  },
  {
    m: 3.69396868e-8,
    radius: 22.93577981651376,
    tilt: -1.54,
    orbitalPeriod: 0.0739726,
    bump: true,
    name: "Moon",
    bodyType: "Moon"
  },
  {
    m: 1.652e-7,
    radius: 36.101083032490976,
    tilt: -0.03,
    orbitalPeriod: 0.24,
    name: "Mercury",
    bump: true,
    bodyType: "Planet"
  },
  {
    m: 2.447e-6,
    radius: 86.95652173913044,
    tilt: 2.63,
    orbitalPeriod: 0.62,
    name: "Venus",
    bodyType: "Planet"
  },
  {
    m: 3.003e-6,
    radius: 91.74311926605505,
    tilt: -23.44,
    orbitalPeriod: 1,
    name: "Earth",
    bump: true,
    bodyType: "Planet"
  },
  {
    m: 3.9217263618e-20,
    orbitalPeriod: 1.2,
    radius: 0.05,
    type: "model",
    asteroidTexture: true,
    name: "Bennu"
  },
  {
    m: 3.213e-7,
    radius: 48.62385321100918,
    tilt: -25.19,
    orbitalPeriod: 1.88,
    name: "Mars",
    bump: true,
    bodyType: "Planet"
  },
  {
    m: 9.543e-4,
    radius: 1027.5229357798166,
    tilt: -3.13,
    orbitalPeriod: 11.86,
    name: "Jupiter",
    bodyType: "Planet"
  },
  { m: 0, orbitalPeriod: 0.14657534, radius: 1.2, type: "model", name: "Juno" },
  {
    m: 1.005570862e-28,
    orbitalPeriod: 0,
    radius: 0.0002791869408256161 / 10000,
    type: "model",
    name: "Rosetta"
  },
  {
    m: 5.359189909029e-15,
    orbitalPeriod: 9.1324109589e-4,
    radius: 0.05,
    type: "model",
    asteroidTexture: true,
    name: "Phobos"
  },
  {
    m: 7.422118532422e-16,
    orbitalPeriod: 0.003424658,
    radius: 0.05,
    type: "model",
    asteroidTexture: true,
    name: "Deimos"
  },
  {
    m: 4.504e-10,
    radius: 5.5,
    tilt: -3,
    orbitalPeriod: 4.6,
    name: "Ceres",
    bump: true,
    bodyType: "Dwarf Planet"
  },
  {
    m: 1.301711480859e-10,
    orbitalPeriod: 3.6,
    radius: 1.7,
    type: "model",
    asteroidTexture: true,
    name: "Vesta"
  },
  {
    m: 4.35914968677e-11,
    orbitalPeriod: 5.57,
    radius: 27,
    type: "model",
    asteroidTexture: true,
    name: "Hygiea"
  },
  {
    m: 1.059871688548e-10,
    orbitalPeriod: 4.61292,
    radius: 1.6,
    type: "model",
    asteroidTexture: true,
    name: "Pallas"
  },
  {
    m: 1.3675763723200001e-11,
    orbitalPeriod: 4.99,
    radius: 1.5,
    type: "model",
    asteroidTexture: true,
    name: "Psyche"
  },
  {
    m: 3.362126177097e-15,
    orbitalPeriod: 1.76,
    radius: 0.5,
    type: "model",
    asteroidTexture: true,
    name: "Eros"
  },
  {
    m: 1.7597490085e-20,
    orbitalPeriod: 1.52,
    radius: 0.5,
    type: "model",
    asteroidTexture: true,
    name: "Itokawa"
  },
  {
    m: 7.038996034e-17,
    orbitalPeriod: 1.4,
    radius: 3,
    type: "model",
    asteroidTexture: true,
    name: "Phaethon"
  },
  {
    m: 2.33292439984e-12,
    orbitalPeriod: 4.67,
    radius: 10,
    type: "model",
    asteroidTexture: true,
    name: "Kleopatra"
  },
  {
    m: 1.508356293e-14,
    orbitalPeriod: 3.29,
    radius: 0.5,
    type: "model",
    asteroidTexture: true,
    name: "Gaspra"
  },
  {
    m: 5.018804172242e-18,
    orbitalPeriod: 6.44,
    radius: 0.013959347041280806,
    tilt: -45,
    type: "model",
    asteroidTexture: true,
    name: "Churyumov\u2013Gerasimenko"
  },
  {
    m: 1.0055708620000001e-16,
    orbitalPeriod: 5.58,
    radius: 7.5,
    type: "model",
    asteroidTexture: true,
    name: "Tempel 1"
  },
  {
    m: 8.547352327000001e-13,
    orbitalPeriod: 3.8,
    radius: 0.8,
    type: "model",
    asteroidTexture: true,
    name: "Lutetia"
  },
  {
    m: 1.005570862e-29,
    orbitalPeriod: 6.2,
    radius: 1.2,
    type: "model",
    name: "Ulysses"
  },
  {
    m: 1.005570862e-29,
    orbitalPeriod: 0.03753425,
    radius: 1.2,
    type: "model",
    name: "TESS"
  },
  {
    m: 1.005570862e-29,
    radius: 0.0001,
    type: "model",
    spacecraft: true,
    name: "Apollo"
  },
  {
    m: 4.490848296995278e-8,
    orbitalPeriod: 0.004794521,
    radius: 24.082568807339452,
    name: "Io",
    bodyType: "Moon"
  },
  {
    m: 2.413291634272764e-8,
    orbitalPeriod: 0.009703205479,
    radius: 22.477064220183486,
    name: "Europa",
    bodyType: "Moon"
  },
  {
    m: 7.450777301989e-8,
    orbitalPeriod: 0.01963471233,
    radius: 34.633027522935784,
    name: "Ganymede",
    bodyType: "Moon"
  },
  {
    m: 5.4300826548000004e-8,
    orbitalPeriod: 0.0465753,
    radius: 35.74007220216607,
    name: "Callisto",
    bodyType: "Moon"
  },
  {
    m: 2.857e-4,
    radius: 862.3853211009175,
    tilt: -26.73,
    orbitalPeriod: 29.46,
    name: "Saturn",
    bodyType: "Planet"
  },
  {
    m: 6.75675e-8,
    radius: 33.944954128440365,
    tilt: 0,
    orbitalPeriod: 0.0436849315,
    name: "Titan",
    bodyType: "Moon"
  },
  {
    m: 5.4311887827482005e-11,
    radius: 3.327981651376147,
    tilt: 0,
    orbitalPeriod: 0.0037540219178,
    name: "Enceladus",
    bump: true,
    bodyType: "Moon"
  },
  {
    m: 9.849839999999999e-10,
    radius: 8.073394495412844,
    tilt: 0,
    orbitalPeriod: 0.0074983972603,
    name: "Dione",
    bump: true,
    bodyType: "Moon"
  },
  {
    m: 1.1596836467392582e-9,
    radius: 11,
    tilt: 0,
    orbitalPeriod: 0.012378663014,
    name: "Rhea",
    bump: true,
    bodyType: "Moon"
  },
  {
    m: 1.89189e-11,
    radius: 2.853211009174312,
    tilt: 0,
    orbitalPeriod: 0.0025808219,
    name: "Mimas",
    bodyType: "Moon"
  },
  {
    m: 3.09309e-10,
    radius: 7.614678899082569,
    tilt: 0,
    orbitalPeriod: 0.005172060274,
    name: "Tethys",
    bump: true
  },
  {
    m: 4.365e-5,
    radius: 370.6422018348624,
    tilt: -82.23,
    orbitalPeriod: 84.01,
    name: "Uranus",
    bodyType: "Planet"
  },
  {
    m: 3.31335599029e-11,
    radius: 3.391743119266055,
    tilt: 0,
    orbitalPeriod: 0.0038725452055,
    name: "Miranda",
    bump: true,
    bodyType: "Moon"
  },
  {
    m: 6.80268688143e-10,
    radius: 8.330275229357799,
    tilt: 0,
    orbitalPeriod: 0.0069051479452,
    name: "Ariel",
    bodyType: "Moon"
  },
  {
    m: 5.89264525132e-10,
    radius: 8.440366972477063,
    tilt: 0,
    orbitalPeriod: 0.011353909589,
    name: "Umbriel",
    bodyType: "Moon"
  },
  {
    m: 1.7733242151370002e-9,
    radius: 11.330275229357799,
    tilt: 0,
    orbitalPeriod: 0.02385170411,
    name: "Titania",
    bump: true,
    bodyType: "Moon"
  },
  {
    m: 1.5153952890340001e-9,
    radius: 10.954128440366974,
    tilt: 0,
    orbitalPeriod: 0.0368855863014,
    name: "Oberon",
    bump: true,
    bodyType: "Moon"
  },
  {
    m: 5.149e-5,
    radius: 355.9633027522936,
    tilt: -28.32,
    orbitalPeriod: 164.8,
    name: "Neptune",
    bodyType: "Planet"
  },
  {
    m: 1.0780769999999999e-8,
    radius: 19.467889908256883,
    tilt: 0,
    orbitalPeriod: 0.01609589,
    name: "Triton",
    bump: true
  },
  {
    m: 2.531524645085e-11,
    radius: 6,
    tilt: 0,
    type: "model",
    asteroidTexture: true,
    orbitalPeriod: 0.0030739726,
    name: "Proteus"
  },

  {
    m: 6.58086572e-9,
    radius: 19.467889908256883,
    tilt: -57.47,
    orbitalPeriod: 0.0175342,
    name: "Pluto",
    bump: true,
    bodyType: "Dwarf Planet"
  },
  {
    m: 7.97417693566e-10,
    radius: 0.507996632996633 * 19.467889908256883,
    tilt: -57.47,
    orbitalPeriod: 0.0175342,
    name: "Charon",
    bump: true
  },

  { m: 1.005570862e-29, radius: 1.2, type: "model", name: "New Horizons" },
  { m: 1.005570862e-29, radius: 1.2, type: "model", name: "Voyager 2" },
  {
    m: 5.149e-5,
    orbitalPeriod: 11400,
    radius: 8.027522935779816,
    tilt: -57.47,
    name: "Sedna",
    bodyType: "Dwarf Planet"
  },
  {
    m: 0.000015015,
    orbitalPeriod: 11200,
    radius: 355.9633027522936,
    tilt: -57.47,
    name: "Planet 9",
    bodyType: "Planet"
  },
  ,
  {
    name: "Betelgeuse",
    m: 17,
    radius: 7846280,
    massType: "star",
    bump: false,
    luminosity: 1,
    temperature: 3600,
    tilt: 0,
    spacecraft: false,
    orbitalPeriod: 0,
    bodyType: "Star"
  },
  {
    name: "Kepler-62",
    massType: "star",
    bump: false,
    luminosity: 1,
    tilt: 0,
    spacecraft: false,
    orbitalPeriod: 0,
    m: 0.69,
    radius: 6251.162790697675,
    temperature: 4925,
    bodyType: "Star"
  },
  {
    name: "Sigma Orionis",
    m: 18,
    radius: 57511,
    massType: "star",
    bump: false,
    luminosity: 1,
    temperature: 35000,
    tilt: 0,
    spacecraft: false,
    orbitalPeriod: 0,
    bodyType: "Star"
  },
  {
    name: "Proxima Centauri",
    m: 0.12,
    radius: 1367.4418604651164,
    temperature: 3050,
    massType: "star",
    bump: false,
    luminosity: 1,
    tilt: 0,
    spacecraft: false,
    orbitalPeriod: 0,
    bodyType: "Star"
  }
];
